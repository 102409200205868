var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"grid grid-cols-1 gap-0 px-4 py-3 my-3 border rounded-md border-oLightestGray md:grid-cols-2"},[_c('div',{staticClass:"flex items-center"},[_c('photo-stack',{attrs:{"size":"sm","count":_vm.getDocsCount,"cover":_vm.getCoverImage},on:{"click":function($event){return _vm.showPopup()}}}),_c('div',{staticClass:"ml-4"},[_c('oto-typography',{attrs:{"variant":"two","text":_vm.title}}),_c('br'),_c('oto-typography',{attrs:{"variant":"three","text":_vm.subtitle}})],1)],1),_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('div',{staticClass:"flex w-auto mt-2 md:items-center md:justify-center md:mt-0"},[_c('button',{staticClass:"border-none rounded-md shadow-lg text-sm outline-none px-6 py-2",on:{"click":function($event){return _vm.handleDownloading()}}},[_c('i',{staticClass:"fas fa-download text-gray-800 mr-3"}),_c('span',{staticClass:"text-gray-800"},[_vm._v("Download")])])]),_c('div',{staticClass:"flex w-auto mt-2 md:items-center md:justify-end md:mt-0"},[_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
return _c('div',{},[_c('button',{staticClass:"flex uppercase items-center pr-6 pl-2 text-sm text-white transition duration-150 ease-in-out border-0 rounded-sm focus:outline-none focus:shadow-solid",class:{
                'bg-variant-green': _vm.currentStatusTitle === 'VERIFIED',
                'bg-variant-red': _vm.currentStatusTitle === 'REJECTED',
                'bg-gray-500': _vm.currentStatusTitle === 'PENDING',
                'bg-yellow-500': _vm.currentStatusTitle === 'EXPIRED',
              },attrs:{"id":"user-menu","aria-label":"User menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"fas fa-chevron-down mr-2"}),_vm._v(" "+_vm._s(_vm.currentStatusTitle)+" ")])])}},{key:"default",fn:function(ref){
              var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"bg-white m-auto"},[_c('div',{staticClass:"w-29 absolute right-0 mt-2"},[(_vm.currentStatusTitle !== 'VERIFIED')?_c('button',{staticClass:"block font-semibold uppercase w-full px-4 py-2 text-sm text-left leading-5 text-variant-green transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){return _vm.handleVerification(true)}}},[_vm._v(" Verify ")]):_vm._e(),(_vm.currentStatusTitle !== 'REJECTED')?_c('button',{staticClass:"block font-semibold uppercase w-full px-4 py-2 text-sm text-left leading-5 text-variant-red transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){return _vm.showRejectModal()}}},[_vm._v(" Reject ")]):_vm._e()])])}}])})],1)])]),_c('CoolLightBox',{attrs:{"effect":"fade","items":_vm.getImages,"index":_vm.image_viewing_index,"useZoomBar":true},on:{"close":function($event){return _vm.hidePopup()}}}),_c('t-modal',{attrs:{"name":("rejectRiderDocument-" + _vm.docId),"variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":!_vm.isLoading,"clickToClose":!_vm.isLoading}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" Reject Rider Document ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"reason-form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleVerification(false))}}},[_c('AppInput',{attrs:{"rules":"required","type":"richselect","name":"reason","label":"Reason","placeholder":"Select a reason","options":_vm.rejectReasons,"hide-search-box":""},model:{value:(_vm.models.reason),callback:function ($$v) {_vm.$set(_vm.models, "reason", $$v)},expression:"models.reason"}}),_c('AppInput',{attrs:{"rules":"","label":"Remark","type":"textarea","name":"remark"},model:{value:(_vm.models.remark),callback:function ($$v) {_vm.$set(_vm.models, "remark", $$v)},expression:"models.remark"}}),_c('button',{ref:"rejectSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.hideRejectModal()}}},[_vm._v(" Cancel ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","disabled":_vm.isLoading,"isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.rejectSubmitButtonRef.click()}}},[_vm._v(" Reject ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }