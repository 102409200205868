<template>
  <section class="relative cursor-pointer" @click="$emit('click')">
    <div
      class="flex overflow-hidden border rounded-md border-oLightestGray"
      :class="getClass()"
    >
      <template v-if="cover">
        <img :src="cover" class="object-fit" :class="getSize()" />
      </template>
      <template v-else>
        <div class="bg-gray-100" :class="getSize()" />
      </template>
    </div>
    <div
      v-if="count"
      class="absolute z-10 w-full h-full px-px py-px text-sm text-white bg-black rounded opacity-50"
      :class="getCountClass()"
    >
      <!-- {{ count }} -->
      <i class="fas fa-eye    "></i>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PhotoStack',
  props: ['size', 'cover', 'count'],
  data() {
    return {
      sm: ['sm', 'small'],
    }
  },
  methods: {
    getSize() {
      let sm = 'sm-cover'
      if (this.sm.includes(this.size)) {
        return sm
      }
      return sm
    },
    getClass() {
      let data
      data = this.getSize() + ' '
      return data
    },
    getCountClass() {
      if (this.sm.includes(this.size)) {
        return 'sm-count'
      }
      return 'sm-count'
    },
  },
}
</script>

<style scoped>
/* small variant */
.sm-cover {
  width: 155px;
  height: 80px;
}
.sm-count {
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  /* left: 72px;
  top: 30px; */
}
.sm-count:hover,
.sm-cover:hover + .sm-count {
  visibility: visible;
}
/* small vairant */
</style>
